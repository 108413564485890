import React from "react";

import SEO from "@/components/seo";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not Found" />
    <h1>PAGE IS NOT FOUND</h1>
    <p>This address doesn't exist...</p>
  </>
);

export default NotFoundPage;
